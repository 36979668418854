define("discourse/plugins/discourse-blizzard-plugin/initializers/customize-admin-widgets", ["exports", "rsvp", "discourse/lib/decorators", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/models/topic", "discourse-i18n", "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note"], function (_exports, _rsvp, _decorators, _pluginApi, _composer, _topic, _discourseI18n, _blizzardAddUserNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "customize-admin-widgets",
    initialize(container) {
      const modal = container.lookup("service:modal");
      const currentUser = container.lookup("service:current-user");
      (0, _pluginApi.withPluginApi)("0.8.17", api => {
        const {
          h
        } = api;
        if (currentUser?.staff) {
          api.modifyClass("model:admin-user", {
            pluginId: "blizzard",
            unsuspend() {
              const doUnsuspend = this._super;
              return this.addNote(() => {
                return doUnsuspend.call(this);
              });
            },
            unsilence() {
              const doUnsilence = this._super;
              return this.addNote(() => {
                return doUnsilence.call(this);
              });
            },
            addNote(callback) {
              const user = this;
              modal.show(_blizzardAddUserNote.default, {
                model: {
                  user,
                  callback
                }
              });
              return _rsvp.Promise.resolve(this);
            }
          });
        }
        api.modifyClass("component:reviewable-item", {
          pluginId: "blizzard",
          clientEdit(reviewable, performAction) {
            this.store.find("post", reviewable.post_id).then(p => {
              _topic.default.find(p.topic_id, {}).then(t_json => {
                const t = _topic.default.create(t_json);
                p.set("topic", t);
                if (!this.currentUser) {
                  const dialog = container.lookup("service:dialog");
                  return dialog.alert((0, _discourseI18n.i18n)("post.controls.edit_anonymous"));
                } else if (!p.can_edit) {
                  return false;
                }
                const opts = {
                  post: p,
                  action: _composer.default.EDIT,
                  draftKey: p.get("topic.draft_key"),
                  draftSequence: p.get("topic.draft_sequence"),
                  skipDraftCheck: true,
                  skipJumpOnSave: true
                };
                const composer = container.lookup("service:composer");
                composer.open(opts);
                return performAction();
              });
            });
            return;
          }
        });

        // Staff can view additional information on a user's posts
        api.decorateWidget("poster-name:after", helper => {
          let result = [];
          let ucf = helper.attrs.userCustomFields;
          if (ucf) {
            if (ucf.battletag) {
              result.push(h("div.battle-tag", ucf.battletag));
            }
            if (ucf.bnet_id) {
              result.push(h("div.bnet-id", ucf.bnet_id));
            }
          }
          if (helper.attrs.created_ip_address) {
            result.push(h("div.created-ip-address", {}, [h("a", {
              href: "https://geoip.battle.net/lookup/" + encodeURI(helper.attrs.created_ip_address),
              target: "_blank"
            }, [String(helper.attrs.created_ip_address)])]));
          }
          if (result.length) {
            return h("div.staff-info", result);
          }
        });

        // Update custom penalty reasons when suspending/silencing
        let updatePenaltyReasons = dt7948.p({
          pluginId: "blizzard",
          reasons(keys) {
            return keys.map(key => {
              return {
                id: key,
                name: (0, _discourseI18n.i18n)(`admin.${key}`)
              };
            });
          },
          setReason() {
            if (this.isCustomReason) {
              this.set("reason", this.customReason);
            } else {
              this.set("reason", this.selectedReason);
            }
          },
          didInsertElement() {
            this._super();
            if (this.penaltyType === "silence") {
              this.set("reasonKeys", ["common.cms.report.ADVERTISING", "common.cms.report.HARASSMENT", "common.cms.report.INAPPROPRIATE", "common.cms.report.REAL_LIFE_THREATS", "common.cms.report.SPAMMING", "common.cms.report.TROLLING", "common.cms.report.OTHER"]);
            } else {
              this.set("reasonKeys", ["common.cms.report.SPAMMING", "common.cms.report.SPAMMING_TROLLING", "common.cms.report.REAL_LIFE_THREATS", "common.cms.report.ILLEGAL", "common.cms.report.ADVERTISING", "common.cms.report.ADVERTISING_STRADING", "common.cms.report.RACIAL_HATRED", "common.cms.report.RELIGION_HATRED", "common.cms.report.IN_GAME", "common.cms.report.OBSCENE_LANGUAGE", "common.cms.report.HARASSMENT", "common.cms.report.OTHER", "common.cms.report.NOT_SPECIFIED", "common.cms.report.TROLLING"]);
            }
            this.set("selectedReason", "common.cms.report.OTHER");
            this.setReason();
          }
        }, [["method", "reasons", [(0, _decorators.default)("reasonKeys")]]]);
        if (currentUser?.staff) {
          api.modifyClass("component:admin-penalty-reason", updatePenaltyReasons);
        }
      });
    }
  };
});